<template>
  <div class="documents-list-view">
    <HubDocuments name="document-list" v-if="loaded" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import HubDocuments from '@blocks/hubs/HubDocuments'
import updateBreadcrumb from '@mixins/updateBreadcrumb'

export default {
  name: 'DocumentsListView',
  mixins: [ updateBreadcrumb ],
  components: {
    HubDocuments
  },
  data () {
    return {
      loaded: false
    }
  },
  created () {
    this.loaded = false
    this.fetchGetPage({ service: 'documents' }).then(() => {
      this.loaded = true
      this.fetchData({ route: this.$route.name, context: 'view' })
    })
  },
  methods: {
    ...mapActions({
      'fetchGetPage': 'page/fetchGetPage',
      'fetchData': 'filters/fetchData'
    })
  }
}
</script>
