<template>
  <ContainerHub class="hub-document">

    <template slot="header">
      <HubHeader
        :titleTag="'h1'"
        :title="title"
        :email="email"
        :description="description"
      />
      <TheToolsDocument />
    </template>

    <template slot="body">
      <CardDocument v-for="(item, index) in items" display="large" :content="item" :key="`documents-${index}`" />
    </template>

    <template slot="footer">
      <BaseButton
        icon="more"
        icon-position="right"
        color="primary"
        v-if="offset < total"
        @click.native="handleClick">
          {{ $t(`message.document.button.more.text`) }}
      </BaseButton>
    </template>
  </ContainerHub>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseButton from '@base/BaseButton'
import ContainerHub from '@containers/ContainerHub'
import CardDocument from '@cards/document/CardDocument'
import HubHeader from './HubHeader'
import TheToolsDocument from '@blocks/tools/TheToolsDocument'

export default {
  name: 'HubDocuments',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  components: {
    BaseButton,
    HubHeader,
    TheToolsDocument,
    CardDocument,
    ContainerHub
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
      title: 'page/getTitle',
      description: 'page/getDescription',
      email: 'page/getEmail',
      items: 'documents/getItems',
      offset: 'documents/getOffset',
      total: 'documents/getTotal'
    }),
    displayType () {
      return 'large'
    }
  },
  methods: {
    ...mapActions({
      fetchGetAddDocuments: 'page/fetchGetAddDocuments'
    }),
    handleClick () {
      this.fetchGetAddDocuments()
    }
  }
}
</script>

<style lang="scss">
  .hub-page{}
</style>
