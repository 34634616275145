<template>
  <TheTools class="tools-document">
    <template slot="nav-left">
      <TheToolsButton :text="'Search'" :icon="'search'" :type="'trigger'" @click="handleSearch" v-if="isDesktop" :active="current === 'search'" />
      <TheToolsButton :text="'Filters'" :icon="'filters'" :type="'trigger'" @click="handleFilters" v-if="isDesktop" :active="current === 'filters'" />
    </template>
    <template slot="nav-right">
      <TheToolsCounter :count="total" />
    </template>
    <template slot="content">
      <TheToolsDropdown :type="'search'" :trigger="searchTrigger" :force-active="current === 'search'" />
      <TheToolsDropdown :type="'filters'" :trigger="filtersTrigger" :force-active="current === 'filters'" />
    </template>
  </TheTools>
</template>

<script>
import { mapGetters } from 'vuex'
import TheTools from './TheTools'
import TheToolsButton from './TheToolsButton'
import TheToolsDropdown from './TheToolsDropdown'
import TheToolsCounter from './TheToolsCounter'

export default {
  name: 'TheToolsDocument',
  components: {
    TheTools,
    TheToolsButton,
    TheToolsCounter,
    TheToolsDropdown
  },
  data () {
    return {
      searchTrigger: {
        text: 'Search',
        icon: 'search'
      },
      filtersTrigger: {
        text: 'Filters',
        icon: 'filters'
      },
      current: ''
    }
  },
  computed: {
    ...mapGetters({
      device: 'breakpoints/getDevice',
      total: 'documents/getTotal'
    }),
    isDesktop () {
      return this.device === 'desktop'
    }
  },
  methods: {
    handleSearch (status) {
      this.current = status ? 'search' : ''
    },
    handleFilters (status) {
      this.current = status ? 'filters' : ''
    },
    handleExport (status) {
      this.current = status ? 'export' : ''
    },
    handleSend (status) {
      this.current = status ? 'send' : ''
    }
  }
}
</script>

<style lang="scss">
.tools-document{
  .filters-category{
    @include media("small"){
      width: 100%;
    }
    @include media("large"){
      width: calc(50% - #{layout("spacing")});
    }
    @include media("large"){
      width: calc(33.33333% - #{layout("spacing")});
    }
  }
}
</style>
